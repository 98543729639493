@use "../../scss/" as *;

.movie-card {
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 160%;
    border-radius: $border-radius;
    margin-bottom: 1rem;

    .btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $black;
        opacity: 0;
        transition: opacity 0.3s ease;
        border-radius: $border-radius;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover::before {
            opacity: 0.8;
        }

        &:hover .btn {
            transform: translate(-50%, -50%) scale(1);
        }
    }
}
